export const ChevronUpIcon = ({ width, className }: { width?: number; className?: string }) => (
  <svg
    className={className}
    width={width ? `${width}px` : '64px'}
    height={width ? `${width}px` : '64px'}
    viewBox="6 4 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke="current"
    transform="rotate(-90)">
    <path
      id="Vector"
      d="M15 19L8 12L15 5"
      stroke="current"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"></path>
  </svg>
)
