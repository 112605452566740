import { useContext } from 'react'
import { DigitalAtomLogo } from '../../../ui/logo/DigitalAtomLogo'
import { HelpInfo } from './HelpInfo'
import { ModalContext } from '../../../context/ModalContextProvider'
import { InfoButton } from '../../../ui/button/InfoButton'

export const AppHeader = () => {
  const setModalContent = useContext(ModalContext)
  const openModalWithHelpInfo = () => {
    setModalContent && setModalContent(<HelpInfo />)
  }

  return (
    <header className={headerStyles}>
      <DigitalAtomLogo className={logoStyles} />
      <InfoButton onClick={() => openModalWithHelpInfo()} className={infoButtonStyles}>
        Инструкция
      </InfoButton>
    </header>
  )
}

const headerStyles = `
  px-2 md:px-6 py-1 md:py-3 
  flex justify-between items-center text-justify
  w-full md:w-11/12 md:max-w-[1200px] md:mx-auto
`
const logoStyles = `w-[290px] md:w-[250px] mb-5 md:mb-0`

const infoButtonStyles = `!text-base !w-full md:!w-min`
