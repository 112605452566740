/* eslint-disable no-console */

export const requestNotificationPermission = async (): Promise<NotificationPermission> => {
  if ('Notification' in window) {
    try {
      return Notification.requestPermission()
    } catch (error) {
      return 'denied'
    }
  } else {
    return 'denied'
  }
}
