import { PropsWithChildren } from 'react'
import { ActionButton } from './ActionButton'
import clsx from 'clsx'

type InfoButtonProps = {
  onClick?: () => void
  className?: string
  disabled?: boolean
} & PropsWithChildren

export const InfoButton = ({ children, className, onClick, disabled }: InfoButtonProps) => (
  <ActionButton
    disabled={disabled}
    onClick={onClick}
    className={clsx('w-min bg-primaryGray hover:bg-primaryGray hover:border-black !text-black', className)}>
    {children}
  </ActionButton>
)
