import { TelegramBlackIcon } from '../../../ui/icons/telegramBlack'
import { TEXTS } from '../../../utils/constants/texts'

export const Footer = () => {
  return (
    <footer className="flex flex-col-reverse md:flex-row gap-2 md:gap-0 text-justify justify-around px-2 md:px-1 items-center bg-black text-white py-1 md:py-3">
      <div className="flex flex-col gap-0.5">
        <p className=" text-center text-base md:text-lg font-medium md:mx-2">© 2024 КОНСИСТ-ОС, РОСАТОМ</p>
        <p className="text-center">
          <span>{TEXTS.version}</span>
        </p>
      </div>
      <p className="text-sm md:text-base font-light w-full md:max-w-[70%] flex justify-center">{TEXTS.footer}</p>
      <a href="https://t.me/T2T_DigitalAtom_Bot" target="_blank" className=" text-primary">
        <TelegramBlackIcon className="fill-white md:w-10 w-9 md:h-10 h-9 md:mx-2" />
      </a>
    </footer>
  )
}
