/* eslint-disable no-console */
import { requestNotificationPermission } from './requestNotificationPermission'

export const showNotification = async (title: string, options?: NotificationOptions) => {
  const permission = await requestNotificationPermission()

  if (permission === 'granted') {
    const notification = new Notification(title, options)
    notification.addEventListener('click', () => window.focus())
  } else {
    console.warn('Уведомления запрещены или их не поддерживает браузер')
  }
}
