import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import './global.css'
import { App } from './App/App'
import { ConfigProvider } from 'antd'

const router = createBrowserRouter([{ path: '/', element: <App /> }])

function Root() {
  return (
    <ConfigProvider
      wave={{ disabled: true }}
      theme={{
        components: {
          Select: {
            optionActiveBg: '#efefef',
            optionSelectedBg: '',
          },
          Switch: {},
        },
        token: {
          colorPrimaryHover: '#118eff',
          colorPrimary: '#118eff',
          borderRadius: 4,
          colorTextTertiary: '#dcdcdc',
          wireframe: false,
        },
      }}>
      <RouterProvider router={router} />
    </ConfigProvider>
  )
}

export default Root
