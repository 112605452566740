import { io } from 'socket.io-client'
import { WebsocketEventsTypes } from './websocketEvents'

const backUrl = process.env.REACT_APP_WS_BACK_URL || ''

export class WebSocketClient {
  private static socket: SocketType = io(backUrl, { autoConnect: false })

  static addEventListener({ event, listener }: AddOffProps) {
    this.socket.on(event, listener)
  }

  static offEventListener({ event, listener }: AddOffProps) {
    this.socket.off(event, listener)
  }

  static isConnected = () => this.socket.connected

  static disconnect(listener?: () => void) {
    listener && this.addEventListener({ event: WebsocketEventsTypes.disconnect, listener })
    this.socket.disconnect()
  }

  static connect(listener?: () => void) {
    listener && this.addEventListener({ event: WebsocketEventsTypes.connect, listener })
    this.socket.connect()
  }

  static emit(event: WebsocketEventsTypes, msg: Record<string, string | number>) {
    this.socket.emit(event, msg)
  }
}

type SocketType = import('socket.io-client').Socket
type AnyEventListener = (value: { [key: string]: string }) => void
type ReportListenerProps = {
  status: number
  downloadLink: string
  transcript: string
  error_message: string
}
export type PositionListenerProps = { job_id: string; position?: number; message?: string }

export type ReportFilesListenerProps = {
  fileName: string
} & ReportListenerProps
export type ReportLinkListenerProps = ReportListenerProps

export type ProgressBarEventListenerProps = { fileName?: string; progress: number }

type ListenerType<T> = (props: T) => void

type AddOffProps =
  | {
      event: WebsocketEventsTypes.token
      listener: ListenerType<{ token: string }>
    }
  | {
      event: WebsocketEventsTypes.reportFiles
      listener: ListenerType<ReportFilesListenerProps>
    }
  | {
      event: WebsocketEventsTypes.reportLink
      listener: ListenerType<ReportLinkListenerProps>
    }
  | { event: WebsocketEventsTypes.position; listener: ListenerType<PositionListenerProps> }
  | {
      event: WebsocketEventsTypes.progressBar
      listener: ListenerType<ProgressBarEventListenerProps>
    }
  | {
      event: WebsocketEventsTypes.connect | WebsocketEventsTypes.disconnect
      listener: AnyEventListener
    }
