import React, { Suspense, lazy, useContext } from 'react'
import { ModalContext } from '../../context/ModalContextProvider'
import { Text, TextType } from '../../ui/text/Text'

const BooksCarousel = lazy(() => import('../ui/BooksCarousel'))

export const useModalContext = () => {
  const setModalContent = useContext(ModalContext)
  const closeModalWithBooks = () => setModalContent && setModalContent(null)
  const openModalWithBooks = () => {
    setModalContent &&
      setModalContent(
        <Suspense
          fallback={
            <Text type={TextType.SIMPLE}>Загрузка рекламы с бесплатными книжками. Пожалуйста, подождите. </Text>
          }>
          <BooksCarousel />
        </Suspense>,
      )
  }

  return { closeModalWithBooks, openModalWithBooks }
}
