export const UploadIcon = ({ width }: { width?: number }) => {
  return (
    <svg
      width={`${width}px` || '25px'}
      height={`${width}px` || '25px'}
      viewBox="0 -2 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg">
      <title>upload</title>
      <desc>Created with Sketch Beta.</desc>
      <defs></defs>
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icon-Set" transform="translate(-569.000000, -674.000000)" fill="#000000">
          <path
            d="M579.732,681.7 L583,677.74 L583,691.998 C583,692.552 583.447,693 584,693 C584.553,693 585,692.552 585,691.998 L585,677.702 L588.299,681.7 C588.69,682.095 589.326,682.095 589.719,681.7 C590.11,681.307 590.11,680.668 589.719,680.274 L584.776,674.283 C584.566,674.073 584.289,673.983 584.016,673.998 C583.742,673.983 583.465,674.073 583.256,674.283 L578.313,680.274 C577.921,680.668 577.921,681.307 578.313,681.7 C578.705,682.095 579.341,682.095 579.732,681.7 L579.732,681.7 Z M598,690 C597.447,690 597,690.448 597,691 L597,698 L571,698 L571,691 C571,690.448 570.553,690 570,690 C569.447,690 569,690.448 569,691 L569,699 C569,699.553 569.447,700 570,700 L598,700 C598.553,700 599,699.553 599,699 L599,691 C599,690.448 598.553,690 598,690 L598,690 Z"
            id="upload"></path>
        </g>
      </g>
    </svg>
  )
}
