export const downloadFileWithToken = (fileLink: string, key: string = 'token') => {
  fetch(fileLink, {
    headers: {
      token: sessionStorage.getItem(key) || '',
    },
  })
    .then((res) => res.blob())
    .then((blob) => {
      const fileUrl = window.URL.createObjectURL(blob)
      const anchor = document.createElement('a')
      anchor.href = fileUrl
      anchor.download = fileLink.split('/').pop() ?? 'transcript.docx'
      anchor.style.display = 'none'
      document.body.appendChild(anchor)
      anchor.click()
      document.body.removeChild(anchor)
      window.URL.revokeObjectURL(fileUrl)
    })
}
