import clsx from 'clsx'
import get from 'lodash/get'
import { PropsWithChildren } from 'react'

export enum TextType {
  SIMPLE = 'simple',
  SECONDARY_TITLE = 'secondary',
  EXPLONATORY = 'explanatory',
  ERROR = 'error',
  PAGE_TITLE = 'page_title',
  DESCRIPTION_PARAGRAPH = 'description_paragraph',
}

type TextProps = {
  type: TextType
  className?: string
} & PropsWithChildren

const typeToTextStyleMapper: Record<TextType, string> = {
  [TextType.SIMPLE]: 'text-sm text-black',
  [TextType.SECONDARY_TITLE]: 'text-xs text-black',
  [TextType.EXPLONATORY]: 'text-sm text-gray-400',
  [TextType.ERROR]: 'text-sm text-primaryRed',
  [TextType.PAGE_TITLE]: 'text-3xl md:text-4xl font-bold text-black',
  [TextType.DESCRIPTION_PARAGRAPH]: 'block text-lg text-black',
}

export const Text = ({ children, type, className }: TextProps) => {
  return <span className={clsx(get(typeToTextStyleMapper, type), className)}>{children}</span>
}
