import map from 'lodash/map'
import { Text, TextType } from '../text/Text'
import { CloseIcon } from '../icon/CloseIcon'
import { bytesToMegaBytes } from '../../utils/bytesToMegaBytes'
import round from 'lodash/round'
import { Fragment, memo, useState } from 'react'
import clsx from 'clsx'
import { SegmentSpinner } from '../icon/spinner/SegmentSpinner'
import { UploadedFileObject } from '../../App/App'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronUpIcon } from '../icon/ChevronUpIcon'
import { CopyButton } from '../button/CopyButton'
import { DownloadIcon } from '../icon/DownloadIcon'
import { downloadFileWithToken } from '../../utils/downloadFileWithToken'
import { Progress } from 'antd'
import { PulseCircle } from '../icon/PulseCircle'

const FileItem = memo(
  ({
    fileObj,
    deleteFileCallback,
    deleteFileDoneLinkCallback,
  }: {
    fileObj: UploadedFileObject
    deleteFileCallback: (_f: UploadedFileObject) => void
    deleteFileDoneLinkCallback: (_f: UploadedFileObject) => void
  }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [wasOpened, setWasOpened] = useState(false)

    const { error, transcript } = fileObj

    const needNotificationMarker = !wasOpened && (error || transcript)
    const openHandler = () => !wasOpened && setWasOpened(true)

    return (
      <Disclosure as={Fragment} defaultOpen={false}>
        {({ open }) => (
          <>
            <Disclosure.Button disabled={!(fileObj.error || fileObj.transcript)} as={Fragment}>
              <div
                onClick={openHandler}
                className={clsx(
                  'flex items-center group/item my-1 md:my-0 ml-1 relative',
                  (fileObj.transcript || fileObj.error) && 'pointer-events-auto cursor-pointer rounded',
                )}>
                {fileObj.isProcessed ? (
                  <ChevronUpIcon
                    width={12}
                    className={clsx(
                      'stroke-black group/item group-hover/item:stroke-primary',
                      open && 'rotate-180 transform',
                    )}
                  />
                ) : (
                  <div className="w-3 h-3 bg-black rounded-full group/item invisible group-hover/item:visible"></div>
                )}
                <span
                  className={clsx(
                    'max-w-[95%] pl-2 py-0.5 flex justify-between items-center grow hover:bg-primaryLightGray rounded relative',
                    open && 'bg-primaryLightGray',
                  )}>
                  <span className="max-w-[90%] flex gap-2 grow">
                    <Text
                      className="max-w-[90%] overflow-hidden whitespace-nowrap text-ellipsis font-semibold"
                      type={fileObj.error ? TextType.ERROR : TextType.SIMPLE}>
                      {fileObj.file.name}
                    </Text>
                    <Text type={TextType.EXPLONATORY}>{round(bytesToMegaBytes(fileObj.file.size), 2)}MB</Text>
                  </span>

                  {needNotificationMarker ? (
                    <PulseCircle className="top-0 -right-2" type={fileObj.error ? 'red' : 'green'} />
                  ) : null}

                  {/* TODO обработать переход по ссылке и автоудаление */}

                  {(fileObj.doneLink && (
                    <button
                      onClick={(e) => {
                        e.stopPropagation()
                        downloadFileWithToken(fileObj.doneLink || '')
                        deleteFileDoneLinkCallback(fileObj)
                        openHandler()
                      }}>
                      <DownloadIcon
                        className="hover:fill-primary scale-110 md:scale-100 hover:scale-110 transition-all"
                        width={20}
                      />
                    </button>
                  )) ||
                    (fileObj.isLoading && (
                      <>
                        <Progress className="w-1/3 mb:w-full" percent={fileObj.progress ?? 0} status="active" />
                        <SegmentSpinner />
                      </>
                    )) || (
                      <button onClick={() => deleteFileCallback(fileObj)}>
                        <CloseIcon className="scale-150 md:scale-100 relative -left-1 md:left-0" />
                      </button>
                    )}
                </span>
              </div>
            </Disclosure.Button>
            <Transition
              enter="transition ease-out duration-200"
              enterFrom="transform scale-10 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition ease-out duration-100"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-10 opacity-0">
              <Disclosure.Panel className="relative  pointer-events-auto pl-3 py-1 border border-gray-300 rounded mb-2 mt-1">
                <CopyButton
                  textForCopy={fileObj.error?.message || fileObj.transcript || ''}
                  className="text-xs ml-auto relative left-full -translate-x-[calc(100%+4px)] mb-0.5">
                  Скопировать в буфер
                </CopyButton>
                <div
                  className={clsx(
                    'max-h-60 overflow-auto pr-3 cursor-text whitespace-pre-wrap',
                    fileObj.error && 'text-primaryRed',
                  )}>
                  {fileObj.error?.message || fileObj.transcript}
                </div>
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    )
  },
)

export const FilesList = ({
  files,
  deleteFileCallback,
  deleteFileDoneLinkCallback,
}: {
  files: Array<UploadedFileObject>
  deleteFileCallback: (_f: UploadedFileObject) => void
  deleteFileDoneLinkCallback: (_f: UploadedFileObject) => void
}) => {
  return (
    <div className={clsx(files.length && 'pr-2 pt-1 w-11/12 mx-auto md:w-full')}>
      {map(files, (file) => {
        return (
          <FileItem
            key={file.file.name}
            fileObj={file}
            deleteFileCallback={deleteFileCallback}
            deleteFileDoneLinkCallback={deleteFileDoneLinkCallback}
          />
        )
      })}
    </div>
  )
}
