/* eslint-disable @typescript-eslint/no-explicit-any */
import { Controller, useForm } from 'react-hook-form'
import { AvailableSettings, SettingType } from '../../App/ui/settings/SettingsList'
import TextInput from '../input/TextInput'
import { ChoicePropsType } from './ChoicePropsType'
import { SettingsSwitch } from './SettingsSwitch'
import { Text, TextType } from '../text/Text'

export const SettingsSwitchWithInput = ({ setting, control, children, reset }: ChoicePropsType) => {
  const { control: emailSwitchControl, watch } = useForm({
    defaultValues: { [setting.name]: false },
  })

  return (
    <Controller
      control={control}
      name={setting.name}
      render={({ field: { disabled }, formState: { errors } }) => {
        return (
          <div className="flex flex-col gap-1">
            <SettingsSwitch
              control={emailSwitchControl as any}
              disabled={disabled}
              onChange={() => reset && reset((old) => ({ ...old, [setting.name]: setting.defaultValue }))}
              setting={{
                name: AvailableSettings.ON_EMAIL,
                type: SettingType.SWITCH,
                values: [
                  { value: true, label: 'Вкл' },
                  { value: false, label: 'Выкл' },
                ],
                defaultValue: false,
              }}>
              {children}
            </SettingsSwitch>
            {watch(setting.name) && (
              <TextInput {...{ control, name: setting.name, placeholder: setting.name }} validate={setting.validate} />
            )}
            <Text type={TextType.ERROR}>{errors[setting.name]?.message}</Text>
          </div>
        )
      }}
    />
  )
}
