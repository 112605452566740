export enum WebsocketEventsTypes {
  connect = 'connect',
  disconnect = 'disconnect',
  reportFiles = 'reportFiles',
  reportLink = 'reportLink',
  token = 'token',

  position = 'position',
  jobStatus = 'job_status',
  trackPosition = 'track_position',

  progressBar = 'progressBar',
}
