import { isValidEmail } from '../../../utils/isValidEmail'

export enum UploadMethod {
  YOUTUBE = 'YouTube',
  DEVICE = 'Device',
}

export enum SettingType {
  SWITCH = 'switch',
  SELECT = 'select',
  SWITCH_WITH_INPUT = 'switch_with_input',
}

export enum AvailableSettings {
  TIMESTAMPS = 'timestamps',
  SPEAKERS = 'speakers',
  UPLOAD_METHOD = 'upload_method',
  LINK = 'link',
  ON_EMAIL = 'email',
}

export type SettingObject = {
  title?: string
  name: AvailableSettings
  comment?: string
  type: SettingType
  values?: Array<{ value: string | boolean; label: string }>
  info?: string
  defaultValue: string | boolean
  validate?: (_v: string | number | boolean) => boolean | string
}

export const settingsList: Array<SettingObject> = [
  {
    name: AvailableSettings.TIMESTAMPS,
    comment: 'Временные метки',
    type: SettingType.SWITCH,
    values: [
      { value: true, label: 'Вкл' },
      { value: false, label: 'Выкл' },
    ],
    defaultValue: false,
  },
  {
    name: AvailableSettings.SPEAKERS,
    comment: 'Разбиение на спикеров',
    type: SettingType.SWITCH,
    values: [
      { value: true, label: 'Вкл' },
      { value: false, label: 'Выкл' },
    ],
    defaultValue: false,
  },
  {
    name: AvailableSettings.ON_EMAIL,
    comment: 'Отправить на email',
    type: SettingType.SWITCH_WITH_INPUT,
    defaultValue: '',
    validate: (v) => isValidEmail(`${v}`) || 'Некорректный email',
  },
  {
    title: 'Способ загрузки',
    name: AvailableSettings.UPLOAD_METHOD,
    type: SettingType.SELECT,
    values: [
      { value: UploadMethod.DEVICE, label: 'С устройства' },
      { value: UploadMethod.YOUTUBE, label: UploadMethod.YOUTUBE },
    ],
    defaultValue: UploadMethod.DEVICE,
  },
]
