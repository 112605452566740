import { Text, TextType } from '../../../ui/text/Text'
import { TEXTS } from '../../../utils/constants/texts'

export const AppTitleInfo = () => (
  <Text className="mb-1 flex flex-col items-center" type={TextType.PAGE_TITLE}>
    Talk2Text
    <Text type={TextType.EXPLONATORY} className="w-11/12 md:w-2/3 leading-4 md:leading-5 text-justify md:text-center">
      {TEXTS.mainDescription}
    </Text>
  </Text>
)
