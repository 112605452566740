import find from 'lodash/find'
import get from 'lodash/get'
import map from 'lodash/map'
import reduce from 'lodash/reduce'
import { useEffect, useRef, useState } from 'react'
import { UseFormSetError, useForm } from 'react-hook-form'
import { SettingsObjectType } from '../../ui/choice/ChoicePropsType'
import {
  PositionListenerProps,
  ProgressBarEventListenerProps,
  ReportFilesListenerProps,
  ReportLinkListenerProps,
  WebSocketClient,
} from '../../websocket/WebsocketClient'
import { WebsocketEventsTypes } from '../../websocket/websocketEvents'
import { AppState, LinkTranscript, UploadedFileObject } from '../App'
import { UploadMethod, settingsList } from '../ui/settings/SettingsList'
import { useModalContext } from './useModalContext'
import { showNotification } from '../../utils/notification/showNotification'

export const useAppForm = () => {
  // -------------- СОСТОЯНИЯ

  const [appState, setAppState] = useState<AppState>(AppState.START)
  const [files, setFiles] = useState<Array<UploadedFileObject>>([])
  const [linkTranscript, setLinkTranscript] = useState<LinkTranscript>({})

  const [positionStatus, setPositionStatus] = useState<PositionListenerProps>()

  // -------------- СОСТОЯНИЕ ФОРМЫ

  const filesSetErrorRef = useRef<UseFormSetError<{
    files: Array<UploadedFileObject>
  }> | null>(null)

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<SettingsObjectType>({
    disabled: appState !== AppState.START,
    mode: 'onSubmit',
    defaultValues: reduce(
      map(settingsList, (setting) => {
        return { [get(setting, 'name')]: get(setting, 'defaultValue') }
      }),
      (acc, current) => {
        return {
          ...acc,
          ...current,
        }
      },
      { link: undefined },
    ),
  })

  // -------------- КОНСТАНТЫ

  const backUrl = process.env.REACT_APP_BACK_URL || ''

  // -------------- ОБРАБОТЧИКИ

  const { openModalWithBooks, closeModalWithBooks } = useModalContext()

  const positionEventListener = (answer: PositionListenerProps) => {
    const { message } = answer

    if (message) {
      if (watch('upload_method') === UploadMethod.DEVICE) {
        setFiles((old) => map(old, (f) => ({ ...f, isLoading: false, isProcessed: true, error: { message } })))
        WebSocketClient.offEventListener({
          event: WebsocketEventsTypes.reportFiles,
          listener: reportFilesEventListener,
        })
      } else if (watch('upload_method') === UploadMethod.YOUTUBE) {
        setLinkTranscript({
          error: {
            message,
          },
          isLoading: false,
        })
        WebSocketClient.offEventListener({ event: WebsocketEventsTypes.reportLink, listener: reportLinkEventListener })
      }
      WebSocketClient.offEventListener({ event: WebsocketEventsTypes.position, listener: positionEventListener })
      WebSocketClient.offEventListener({ event: WebsocketEventsTypes.progressBar, listener: progressBarEventListener })
    }

    setPositionStatus(answer)
  }

  const reportFilesEventListener = (answer: ReportFilesListenerProps) => {
    if (Number(answer?.status) === 200) {
      showNotification('Транскрибация завершена', { body: answer.fileName })
      setFiles((old) =>
        map(old, (f) =>
          f.file.name === answer.fileName
            ? {
                ...f,
                isLoading: false,
                isProcessed: true,
                doneLink: backUrl + answer.downloadLink,
                transcript: answer.transcript,
              }
            : f,
        ),
      )
    } else {
      showNotification('Ошибка транскрибации', { body: answer.fileName })
      setFiles((old) =>
        map(old, (f) =>
          f.file.name === answer.fileName
            ? { ...f, isLoading: false, isProcessed: true, error: { message: answer.error_message } }
            : f,
        ),
      )
    }
    if (!find(files, (file) => file.isLoading)) {
      closeModalWithBooks()
      setAppState(AppState.DONE)
      setPositionStatus(undefined)

      WebSocketClient.offEventListener({ event: WebsocketEventsTypes.reportFiles, listener: reportFilesEventListener })
      WebSocketClient.offEventListener({ event: WebsocketEventsTypes.position, listener: positionEventListener })
      WebSocketClient.offEventListener({ event: WebsocketEventsTypes.progressBar, listener: progressBarEventListener })
    }
  }

  const reportLinkEventListener = (answer: ReportLinkListenerProps) => {
    if (Number(answer.status) === 200) {
      showNotification('Транскрибация завершена', { body: 'Ресурс по ссылке прошел успешную обработку' })
      const fullLinkToDownloadFile = backUrl + answer.downloadLink
      setLinkTranscript({
        transcript: answer.transcript,
        doneLink: fullLinkToDownloadFile,
        isLoading: false,
      })
    } else {
      showNotification('Ошибка транскрибации', { body: 'Возникла ошибка при обработке ресурса по ссылке' })
      setLinkTranscript({
        error: {
          message: answer.error_message || 'Проблемы на стороне сервера',
        },
        isLoading: false,
      })
    }
    closeModalWithBooks()
    setAppState(AppState.DONE)
    setPositionStatus(undefined)

    WebSocketClient.offEventListener({ event: WebsocketEventsTypes.reportLink, listener: reportLinkEventListener })
    WebSocketClient.offEventListener({ event: WebsocketEventsTypes.position, listener: positionEventListener })
    WebSocketClient.offEventListener({ event: WebsocketEventsTypes.progressBar, listener: progressBarEventListener })
  }

  const deleteLinkTranscriptDoneLinkCallback = () => setLinkTranscript((old) => ({ ...old, doneLink: undefined }))

  const resetStateFunc = () => {
    setFiles([])
    filesSetErrorRef.current && filesSetErrorRef.current('files', {})
    setLinkTranscript({})
    reset()
    setAppState(AppState.START)
    setPositionStatus(undefined)
  }

  const progressBarEventListener = (answer: ProgressBarEventListenerProps) => {
    if (answer.fileName) {
      setFiles((old) =>
        map(old, (f) =>
          f.file.name === answer.fileName
            ? {
                ...f,
                progress: answer.progress,
              }
            : f,
        ),
      )
    } else {
      setLinkTranscript((old) => ({ ...old, ...answer }))
    }
  }

  // -------------- SUBMIT

  const deviceSubmit = () => {
    if (watch('upload_method') === UploadMethod.DEVICE && !files.length) {
      filesSetErrorRef.current && filesSetErrorRef.current('files', { message: 'Необходимо выбрать файл' })
      return Promise.resolve(false)
    }

    setAppState(AppState.PROCESSING)
    setFiles((old) =>
      map(old, (f) => ({
        ...f,
        isLoading: true,
      })),
    )

    WebSocketClient.addEventListener({ event: WebsocketEventsTypes.reportFiles, listener: reportFilesEventListener })
    WebSocketClient.addEventListener({ event: WebsocketEventsTypes.position, listener: positionEventListener })
    WebSocketClient.addEventListener({ event: WebsocketEventsTypes.progressBar, listener: progressBarEventListener })

    const promise = Promise.all(
      map(files, async (file) => {
        const formData = new FormData()
        formData.append('file', file.file)
        formData.append('link', 'None')
        formData.append('timestamps', getValues('timestamps'))
        formData.append('speakers', getValues('speakers'))
        formData.append('upload_method', getValues('upload_method'))
        formData.append('email', getValues('email') || 'None')

        return fetch(`${backUrl}/report`, {
          method: 'Post',
          body: formData,
        })
          .catch((_reason) => {
            setFiles((old) => {
              return map(old, (f) =>
                f.file.name === file.file.name
                  ? {
                      ...file,
                      isLoading: false,
                      isProcessed: true,
                      error: { message: 'Проблемы на стороне сервера' },
                    }
                  : f,
              )
            })
          })
          .then(async (response) => {
            if (response && response.status !== 202) {
              const message = (await response.json()).error || 'Проблемы на стороне сервера'
              setFiles((old) =>
                map(old, (f) =>
                  f.file.name === file.file.name
                    ? {
                        ...file,
                        isLoading: false,
                        isProcessed: true,
                        error: { message },
                      }
                    : f,
                ),
              )
            } else {
              const job_id = (await response?.json())?.job_id
              job_id && WebSocketClient.emit(WebsocketEventsTypes.trackPosition, { job_id })
            }
          })
      }),
    )

    openModalWithBooks()

    return promise
  }

  const youtubeSubmit = async () => {
    setLinkTranscript({
      isLoading: true,
    })
    setAppState(AppState.PROCESSING)

    WebSocketClient.addEventListener({
      event: WebsocketEventsTypes.reportLink,
      listener: reportLinkEventListener,
    })
    WebSocketClient.addEventListener({ event: WebsocketEventsTypes.position, listener: positionEventListener })
    WebSocketClient.addEventListener({ event: WebsocketEventsTypes.progressBar, listener: progressBarEventListener })

    const formData = new FormData()
    formData.append('file', 'None')
    formData.append('link', getValues('link'))
    formData.append('timestamps', getValues('timestamps'))
    formData.append('speakers', getValues('speakers'))
    formData.append('upload_method', getValues('upload_method'))
    formData.append('email', getValues('email') || 'None')

    const promise = fetch(`${backUrl}/report`, {
      method: 'Post',
      body: formData,
    })
      .catch((_reason) => {
        setLinkTranscript({
          error: {
            message: 'Проблемы на стороне сервера',
          },
          isLoading: false,
        })
      })
      .then(async (response) => {
        if (response && response.status !== 202) {
          const message = (await response.json()).error || 'Проблемы на стороне сервера'
          setLinkTranscript({
            error: {
              message,
            },
            isLoading: false,
          })
        } else {
          const job_id = (await response?.json())?.job_id
          job_id && WebSocketClient.emit(WebsocketEventsTypes.trackPosition, { job_id })
        }
      })

    openModalWithBooks()

    return promise
  }

  const submitFuncMapper: Record<UploadMethod, () => Promise<void | void[] | boolean>> = {
    [UploadMethod.DEVICE]: deviceSubmit,
    [UploadMethod.YOUTUBE]: youtubeSubmit,
  }

  const submitFunc = handleSubmit(async () => {
    await get(submitFuncMapper, watch('upload_method'), () => Promise.resolve(false))()

    setTimeout(() => getValues('email') && resetStateFunc(), 1000)
  })

  // -------------- useEffects

  useEffect(() => {
    if (files.length) {
      filesSetErrorRef.current && filesSetErrorRef.current('files', {})
    }
  }, [files])

  useEffect(() => {
    WebSocketClient.addEventListener({
      event: WebsocketEventsTypes.token,
      listener: (value: { token: string }) => {
        sessionStorage.setItem('token', value.token)
      },
    })
    WebSocketClient.connect(() => {
      // eslint-disable-next-line no-console
      console.log('Connected')
    })
    return () => {
      WebSocketClient.disconnect()
    }
  }, [])

  return {
    appState,
    control,
    reset,
    submitFunc,
    resetStateFunc,
    watch,
    files,
    filesSetErrorRef,
    setFiles,
    linkTranscript,
    errors,
    openModalWithBooks,
    deleteLinkTranscriptDoneLinkCallback,

    positionStatus,
  }
}
