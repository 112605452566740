import clsx from 'clsx'

export const SegmentSpinner = ({ className }: { className?: string }) => (
  <span
    className={clsx(
      'h-4 w-4 animate-spin rounded-full border-2 border-solid border-current border-r-transparent  text-primary',
      className,
    )}
    role="status"
  />
)
