import clsx from 'clsx'
import { ActionButton } from '../../ui/button/ActionButton'
import { AppState } from '../App'
import { PositionListenerProps } from '../../websocket/WebsocketClient'
import { Text, TextType } from '../../ui/text/Text'

export const ActionButtons = (props: PropsType) => {
  const { openModalWithBooks, mainButtonText, appState, mainButtonOnclick, positionStatus } = props

  const PositionText = () => {
    if (!positionStatus) return <></>

    const { position, message } = positionStatus

    return message ? (
      <Text type={TextType.ERROR}>{message}</Text>
    ) : (
      <Text type={TextType.SIMPLE}>{`Вы ${position} в очереди`}</Text>
    )
  }

  return (
    <div className="flex flex-col gap-0.5 items-center">
      <PositionText />
      <div className="flex gap-2">
        <ActionButton disabled={appState === AppState.PROCESSING} onClick={mainButtonOnclick}>
          {mainButtonText}
        </ActionButton>
        {(appState === AppState.PROCESSING || appState === AppState.DONE) && (
          <ActionButton
            className={clsx(
              appState === AppState.DONE && 'bg-primaryGray hover:bg-primaryGray hover:border-black !text-black',
            )}
            onClick={openModalWithBooks}>
            Посмотреть книги
          </ActionButton>
        )}
      </div>
    </div>
  )
}

type PropsType = {
  openModalWithBooks: () => void
  mainButtonText: string | boolean
  appState: AppState
  mainButtonOnclick: () => void
  positionStatus?: PositionListenerProps
}
