import clsx from 'clsx'
import { FC } from 'react'

export const PulseCircle: FC<PropsType> = (props) => {
  const { className, type = 'primary' } = props

  return (
    <span className={clsx('absolute flex h-2 w-2', className)}>
      <span
        className={clsx(
          'animate-ping absolute inline-flex h-full w-full rounded-full opacity-75',
          (type === 'green' && `bg-green-600/90`) ||
            (type === 'red' && `bg-primaryRed/90`) ||
            (type === 'primary' && `bg-primary/90`),
        )}></span>
      <span
        className={clsx(
          'relative inline-flex rounded-full h-2 w-2',
          (type === 'green' && `bg-green-600/90`) ||
            (type === 'red' && `bg-primaryRed/90`) ||
            (type === 'primary' && `bg-primary/90`),
        )}></span>
    </span>
  )
}

type PropsType = { className?: string; type?: 'green' | 'red' | 'primary' }
