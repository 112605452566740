export const TEXTS = {
  // ------------------ ГЛОБАЛЬНЫЕ ЧАСТИ

  footer: `Мы не храним ваши медиафайлы и их расшифровки и не используем их для дополнительного обучения наших моделей. Мы
        удаляем все ваши данные сразу после отправки вам.`,
  mainDescription: `— удобный инструмент для выделения речи в текстовом формате из ваших аудио- и видеофайлов с использованием методов
      искусственного интеллекта.`,

  // ------------------ ФОРМЫ

  uploadFileForm: `Размер файла не более 100Mb, форматы: WAV, MP3, WEBA, WEBM, OGG, MP4, MPEG4`,

  // ------------------ ВЕРСИИ

  version: 'Версия 0.4.1 (dev)',

  // ------------------ ВЕРСИИ

  helpInfoTexts: [
    'Выберите нужные параметры для расшифровки на главной странице сайта \n или в меню настроек бота.',
    'Загрузите файл в разрешенном расширении.',
    'Ожидайте ответа с расшифровкой. Иногда это может занять продолжительное количество времени. \n Время расшифровки зависит от размера файла и загруженности сервиса.',
    'Если у Вас появляется сообщение об ошибке или возникают вопросы по пользованию системой, то напишите нам на почту\nsupport@t2t.digitalatom.ru',
  ],
}
