import { InfoButton } from '../../../ui/button/InfoButton'
import { Text, TextType } from '../../../ui/text/Text'
import map from 'lodash/map'
import { TEXTS } from '../../../utils/constants/texts'

export const HelpInfo = () => (
  <div className="flex flex-col gap-8 md:gap-4 overflow-y-auto m-auto h-full justify-center">
    <Text className="text-center" type={TextType.PAGE_TITLE}>
      Инструкция
    </Text>
    <Text type={TextType.DESCRIPTION_PARAGRAPH}>
      <ul className=" text-justify md:text-center md:whitespace-pre-line space-y-3 md:space-y-2 list-disc list-inside leading-[120%] md:leading-[130%]">
        {map(TEXTS.helpInfoTexts, (text,i) => (
          <li key={i}>{text}</li>
        ))}
      </ul>
    </Text>
    <div className="flex justify-end">
      <InfoButton className="!text-base !w-full md:!w-min">
        <a href="mailto:support@t2t.digitalatom.ru">Связаться</a>
      </InfoButton>
    </div>
  </div>
)
