import { Controller } from 'react-hook-form'
import { ChoicePropsType } from './ChoicePropsType'
import { Switch } from 'antd'
import clsx from 'clsx'

export const SettingsSwitch = ({
  setting,
  control,
  children,
  disabled: propsDisabled,
  onChange: onChangeProps,
}: ChoicePropsType) => {
  return (
    <Controller
      control={control}
      name={setting.name}
      render={({ field: { onChange, value, disabled } }) => (
        <span className="flex gap-2">
          <Switch
            disabled={propsDisabled !== undefined ? propsDisabled : disabled}
            className={clsx(!value && 'bg-primaryGray', '!duration-0 transition-none !border-transparent')}
            style={{ backgroundColor: value ? 'primaryGray' : 'primaryGray' }}
            checked={value as boolean}
            onChange={(e) => {
              onChange(e)
              onChangeProps && onChangeProps()
            }}
          />
          {children}
        </span>
      )}
    />
  )
}
