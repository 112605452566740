import { PropsWithChildren, useState } from 'react'
import { ActionButton } from './ActionButton'
import clsx from 'clsx'

type CopyButtonProps = {
  className?: string
  disabled?: boolean
  textForCopy: string
} & PropsWithChildren

const COPIED_STRING = 'В буфере'

export const CopyButton = ({ children, className, disabled, textForCopy }: CopyButtonProps) => {
  const initialContent = children || 'Copy'
  const [content, setContent] = useState(initialContent)
  return (
    <ActionButton
      disabled={disabled}
      onClick={() => {
        navigator.clipboard.writeText(textForCopy)
        setContent(COPIED_STRING)
        setTimeout(() => setContent(initialContent), 3000)
      }}
      className={clsx(className, 'duration-200', content === COPIED_STRING && '!bg-green-500 hover:!bg-green-600')}>
      {content}
    </ActionButton>
  )
}
