import { Fragment, PropsWithChildren, createContext, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CloseIcon } from '../ui/icon/CloseIcon'

type ModalContext = {
  modalContent: JSX.Element
}

export const ModalContext = createContext<React.Dispatch<React.SetStateAction<JSX.Element | null>> | null>(null)

export const ModalContextProvider = ({ children }: PropsWithChildren) => {
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null)
  return (
    <ModalContext.Provider value={setModalContent}>
      {children}
      <Transition appear show={!!modalContent} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setModalContent(null)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-black/25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95">
                <Dialog.Panel className="relative w-full max-w-3xl h-[600px] md:h-auto md:max-h-[600px] transform overflow-hidden overflow-y-auto rounded bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <button className="absolute right-2 top-2" onClick={() => setModalContent(null)}>
                    <CloseIcon />
                  </button>
                  {modalContent}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </ModalContext.Provider>
  )
}
