import { Input } from 'antd'
import { Controller } from 'react-hook-form'
import { AvailableSettings } from '../../App/ui/settings/SettingsList'
import { SettingsControlType } from '../choice/ChoicePropsType'

export const TextInput = ({
  control,
  name,
  placeholder,
  className,
  validate,
}: {
  name: AvailableSettings
  control: SettingsControlType
  placeholder?: string
  className?: string
  validate?: (_v: string | number | boolean) => boolean | string
}) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={{
        validate: (v) => (validate ? validate(v || '') : true),
      }}
      render={({ field: { value, ...rest }, formState: { errors } }) => (
        <Input
          {...(errors[name]?.message && { status: 'error' })}
          className={className}
          value={value as string}
          {...rest}
          placeholder={placeholder}
        />
      )}
    />
  )
}

export default TextInput
