import clsx from 'clsx'
import { ActionButton } from '../../button/ActionButton'
import { UploadIcon } from '../../icon/UploadIcon'
import { Text, TextType } from '../../text/Text'
import { ChangeEvent, Dispatch, memo, useEffect, useRef, useState } from 'react'
import { UseFormSetError, useForm } from 'react-hook-form'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import split from 'lodash/split'
import last from 'lodash/last'
import join from 'lodash/join'
import uniqBy from 'lodash/uniqBy'
import map from 'lodash/map'
import { UploadedFileObject } from '../../../App/App'
import { bytesToMegaBytes } from '../../../utils/bytesToMegaBytes'
import { TEXTS } from '../../../utils/constants/texts'

const availableFileTypes = ['wav', 'mp3', 'weba', 'webm', 'ogg', 'mp4', 'mpeg4']
const filesValidate = (files: Array<File>) => {
  const filteredFiles = filter(files, (file) => {
    return (
      includes(availableFileTypes, last(split(file.name, '.'))?.toLocaleLowerCase()) &&
      bytesToMegaBytes(file.size) < 100
    )
  })
  if ((!filteredFiles.length && files?.length) || filteredFiles.length !== files?.length) {
    return 'Пожалуйста, выбирайте файлы доступных расширений и размера'
  }
  return true
}

export const UploadFileForm = memo(
  ({
    setHandler,
    disabled,
    filesSetErrorRef,
  }: {
    setHandler: Dispatch<React.SetStateAction<Array<UploadedFileObject>>>
    disabled?: boolean
    filesSetErrorRef: React.MutableRefObject<UseFormSetError<{
      files: Array<File>
    }> | null>
  }) => {
    const [isDraggable, setIsDraggable] = useState(false)
    const filesInputRef = useRef<HTMLInputElement | null>(null)
    const {
      register,
      setValue,
      setError,
      formState: { errors },
    } = useForm<{ files: Array<File> }>({ mode: 'onChange' })

    useEffect(() => {
      filesSetErrorRef.current = setError
    }, [])

    const { ref, ...filesRegister } = register('files', {
      validate: filesValidate,
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && filesValidate(Array.from(e.target.files)) === true) {
          setHandler((old) =>
            uniqBy(
              [...old, ...(map(e.target.files, (file) => ({ file: file, isLoading: false })) || [])],
              (f) => f.file.name,
            ),
          )
        }
        setValue('files', [])
      },
    })

    const handleDragOver = (e: React.DragEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const handleDragLeave = (e?: React.DragEvent<HTMLFormElement>) => {
      e?.preventDefault()
      e?.stopPropagation()
      setIsDraggable(false)
    }

    const handleDragEnter = (e: React.DragEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
      setIsDraggable(true)
    }

    const handleDrop = (e: React.DragEvent<HTMLFormElement>) => {
      e.preventDefault()
      e.stopPropagation()
      const { files } = e.dataTransfer as unknown as { files: Array<File> }
      if (files && files.length) {
        filesValidate(files) === true &&
          setHandler((old) =>
            uniqBy([...old, ...map(files, (file) => ({ file: file, isLoading: false }))], (f) => f.file.name),
          )
        setValue('files', files, { shouldValidate: true })
      }
      handleDragLeave()
    }

    const dragHandlers = {
      onDrop: handleDrop,
      onDragEnter: handleDragEnter,
      onDragLeave: handleDragLeave,
      onDragOver: handleDragOver,
    }

    return (
      <form
        {...dragHandlers}
        className={clsx(
          'flex flex-col md:flex-row items-center justify-between gap-4 px-3 md:px-5 md:py-7 py-4 rounded w-full bg-primaryGray relative',
          'transition-all duration-300 border border-dashed  hover:border-primary',
          isDraggable ? 'border-primary' : 'border-primaryGray',
        )}>
        <div className={clsx('flex items-center gap-4', isDraggable && 'pointer-events-none')}>
          <UploadIcon width={35} />
          <div className="flex flex-col w-3/4 md:w-full">
            <Text type={TextType.SIMPLE}>Перетащите файл в эту область</Text>
            <Text type={TextType.EXPLONATORY}>{TEXTS.uploadFileForm}</Text>
          </div>
        </div>
        <ActionButton
          disabled={disabled}
          onClick={() => filesInputRef.current?.click()}
          className={clsx('w-full md:w-min', isDraggable && 'pointer-events-none')}>
          Выбрать файлы
        </ActionButton>
        <input
          type="file"
          multiple={Boolean(process.env.REACT_APP_CAN_MULTIPLE_FILES) || true}
          accept={`.${join(availableFileTypes, ',.')}`}
          {...filesRegister}
          ref={(e) => {
            ref(e)
            filesInputRef.current = e
          }}
          className="hidden"
        />

        {errors.files?.message && (
          <Text
            className="text-center md:absolute md:bottom-1 md:left-1/2 md:-translate-x-1/2 md:whitespace-nowrap"
            type={TextType.ERROR}>
            {errors.files?.message}
          </Text>
        )}
      </form>
    )
  },
)
