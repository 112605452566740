import clsx from 'clsx'
import { PropsWithChildren } from 'react'

type ActionButtonProps = {
  onClick?: () => void
  className?: string
  disabled?: boolean
} & PropsWithChildren

export const ActionButton = ({ children, className, onClick, disabled }: ActionButtonProps) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        'bg-primary disabled:bg-primaryGray disabled:text-black hover:bg-darkPrimary disabled:hover:bg-primaryGray transition-colors text-white h-min px-2 py-1 rounded border whitespace-nowrap',
        'text-sm',
        className,
      )}>
      {children}
    </button>
  )
}
