import clsx from 'clsx'
import noop from 'lodash/noop'
import { Suspense, lazy } from 'react'
import { FilesForm } from '../ui/forms/files/FilesForm'
import { Text, TextType } from '../ui/text/Text'
import { AppTitleInfo } from './components/appTitleInfo/AppTitleInfo'
import { Footer } from './components/footer/Footer'
import { AppHeader } from './components/header/Header'
import { useAppForm } from './hooks/useAppForm'
import { ActionButtons } from './ui/ActionButtons'
import { Settings } from './ui/settings/Settings'
import { UploadMethod } from './ui/settings/SettingsList'

const LinkUploadForm = lazy(() => import('./ui/LinkUploadForm'))

export enum AppState {
  START = 'start',
  PROCESSING = 'processing',
  DONE = 'done',
}

export type UploadedFileObject = {
  file: File
  isLoading: boolean
  isProcessed?: boolean
  doneLink?: string
  transcript?: string
  error?: {
    message: string
  }
  progress?: number
}

export type LinkTranscript = {
  transcript?: string
  doneLink?: string
  isLoading?: boolean
  error?: { message: string }
  progress?: number
}

export const App = () => {
  const appMethods = useAppForm()

  const { appState, submitFunc, resetStateFunc, watch, control, reset, files } = appMethods
  const { filesSetErrorRef, setFiles, linkTranscript, errors, positionStatus } = appMethods
  const { openModalWithBooks, deleteLinkTranscriptDoneLinkCallback } = appMethods

  const mainButtonText =
    (appState === AppState.PROCESSING && 'Обработка') ||
    (appState === AppState.START && 'Начать') ||
    (appState === AppState.DONE && 'Загрузить снова')
  const mainButtonOnclick =
    (appState === AppState.START && submitFunc) || (appState === AppState.DONE && resetStateFunc) || noop

  return (
    <div className="flex flex-col justify-between gap-10 h-full">
      <AppHeader />
      <div className="flex flex-col items-center gap-6 w-full">
        <AppTitleInfo />
        <Settings control={control} reset={reset} />

        {watch('upload_method') === UploadMethod.DEVICE && (
          <div className="flex flex-col items-center gap-1 w-full relative">
            <FilesForm
              filesSetErrorRef={filesSetErrorRef}
              disabled={appState !== AppState.START}
              files={files}
              setFiles={setFiles}
              className={clsx('w-full md:w-max 2xl:w-[768px] animate-appearance')}
            />
          </div>
        )}

        {watch('upload_method') === UploadMethod.YOUTUBE && (
          <Suspense fallback={<Text type={TextType.SIMPLE}>Загрузка Формы. Пожалуйста, подождите. </Text>}>
            <LinkUploadForm
              control={control}
              linkTranscript={linkTranscript}
              errors={errors}
              deleteLinkTranscriptDoneLinkCallback={deleteLinkTranscriptDoneLinkCallback}
            />
          </Suspense>
        )}
        <ActionButtons {...{ openModalWithBooks, mainButtonOnclick, mainButtonText, appState, positionStatus }} />
      </div>
      <Footer />
    </div>
  )
}
