import { Popover } from 'antd'
import get from 'lodash/get'
import map from 'lodash/map'
import { ChoicePropsType, SettingsControlType, SettingsResetType } from '../../../ui/choice/ChoicePropsType'
import { SettingsSelect } from '../../../ui/choice/SettingsSelect'
import { SettingsSwitch } from '../../../ui/choice/SettingsSwitch'
import { SettingsSwitchWithInput } from '../../../ui/choice/SettingsSwitchWithInput'
import { InfoIcon } from '../../../ui/icon/InfoIcon'
import { Text, TextType } from '../../../ui/text/Text'
import { SettingType, settingsList } from './SettingsList'

const settingTypeToComponentMapper: Record<SettingType, (_obj: ChoicePropsType) => JSX.Element> = {
  [SettingType.SWITCH]: SettingsSwitch,
  [SettingType.SELECT]: SettingsSelect,
  [SettingType.SWITCH_WITH_INPUT]: SettingsSwitchWithInput,
}

export const Settings = ({ control, reset }: { control: SettingsControlType; reset: SettingsResetType }) => {
  return (
    <form className="flex flex-col gap-3">
      {map(settingsList, (setting) => {
        const Element = get(settingTypeToComponentMapper, setting.type)
        return (
          <div key={setting.name} className="flex flex-col">
            <Text type={TextType.SECONDARY_TITLE}>{setting.title}</Text>
            <span className="">
              <Element setting={setting} control={control} reset={reset}>
                <Text type={TextType.SIMPLE}>{setting.comment}</Text>
              </Element>

              {setting.info && (
                <Popover
                  placement="bottom"
                  content={<div className="max-w-[300px]">{setting.info}</div>}
                  title={setting.comment}
                  trigger="hover">
                  <span className="cursor-help">
                    <InfoIcon className=" fill-gray-700" />
                  </span>
                </Popover>
              )}
            </span>
          </div>
        )
      })}
    </form>
  )
}
