import ReactDOM from 'react-dom/client'
import Root from './Root'
import { AppContextProvider } from './context/AppContextProvider'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
document.getElementById('root')?.classList.add('font-manrope')
root.render(
  <AppContextProvider>
    <Root />
  </AppContextProvider>,
)
