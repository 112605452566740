import { useCallback } from 'react'
import { UploadFileForm } from '../upload/UploadFileForm'
import { FilesList } from '../../list/FilesList'
import filter from 'lodash/filter'
import map from 'lodash/map'
import clsx from 'clsx'
import { UseFormSetError } from 'react-hook-form'
import { UploadedFileObject } from '../../../App/App'

export const FilesForm = (props: PropsType) => {
  const { files, setFiles, className, disabled, filesSetErrorRef } = props

  const deleteFileCallback = useCallback(
    (fileObj: UploadedFileObject) =>
      setFiles((old) =>
        filter(
          old,
          (f: UploadedFileObject, _i, _a) => f.file.name !== fileObj.file.name && f.file.size !== fileObj.file.size,
        ),
      ),
    [],
  )

  const deleteFileDoneLinkCallback = useCallback(
    (fileObj: UploadedFileObject) =>
      setFiles((old) =>
        map(old, (f: UploadedFileObject) =>
          fileObj.doneLink && fileObj.doneLink === f.doneLink ? { ...f, doneLink: undefined } : f,
        ),
      ),
    [],
  )

  return (
    <div className={clsx(disabled && 'pointer-events-none', className)}>
      <UploadFileForm filesSetErrorRef={filesSetErrorRef} setHandler={setFiles} disabled={disabled} />
      <FilesList
        files={files}
        deleteFileCallback={deleteFileCallback}
        deleteFileDoneLinkCallback={deleteFileDoneLinkCallback}
      />
    </div>
  )
}

type PropsType = {
  files: Array<UploadedFileObject>
  setFiles: React.Dispatch<React.SetStateAction<UploadedFileObject[]>>
  className?: string
  disabled?: boolean
  filesSetErrorRef: React.MutableRefObject<UseFormSetError<{
    files: Array<File>
  }> | null>
}
