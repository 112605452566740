import { Controller } from 'react-hook-form'
import { ChoicePropsType } from './ChoicePropsType'
import map from 'lodash/map'
import { Select } from 'antd'

export const SettingsSelect = ({ setting, control, children }: ChoicePropsType) => {
  return (
    <Controller
      control={control}
      name={setting.name}
      render={({ field: { onChange, value, disabled } }) => (
        <>
          <Select
            defaultValue={setting.defaultValue}
            value={value}
            menuItemSelectedIcon={<span className="h-2 w-2 rounded-full bg-black" />}
            disabled={disabled}
            className="w-full"
            onChange={onChange}
            options={map(setting.values, (val) => val)}
          />
          {children}
        </>
      )}
    />
  )
}
